<template>
    <v-sheet class="pa-2 ma-2">
        <v-sheet class="mt-2 mb-2">
            <TipTapReader 
                style="min-height:700px;"
                class="tiptap_style pa-2"
                :options="options"
                v-if="options.content"
            />
        </v-sheet>
        <v-sheet v-if="$store.state.type=='관리자'">
            <v-sheet class="d-flex justify-center pa-1">
                <v-btn outlined class="px-10" color="orange" @click="update()">
                    수정하기 (관리자)
                </v-btn>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"

export default {
    props: ["title"],

    components: {
        TipTapReader
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: false,
            supportImage: true,
            supportVideo: true,
        }
    }),

    mounted() {
        this.$http.post('/api/html/select/specific/title', {
            params: {
                title: this.title
            }
        }).then(res => {
            this.options.content=res.data[0].content;
        })
    },

    methods: {
        update(){
            this.$router.push('/policy/'+this.title+'?type=write')
        }
    }
}
</script>
